exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-blog-js": () => import("./../../../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-clinica-js": () => import("./../../../src/pages/clinica.js" /* webpackChunkName: "component---src-pages-clinica-js" */),
  "component---src-pages-contactos-js": () => import("./../../../src/pages/contactos.js" /* webpackChunkName: "component---src-pages-contactos-js" */),
  "component---src-pages-equipa-js": () => import("./../../../src/pages/equipa.js" /* webpackChunkName: "component---src-pages-equipa-js" */),
  "component---src-pages-harmonizacao-facial-js": () => import("./../../../src/pages/harmonizacao-facial.js" /* webpackChunkName: "component---src-pages-harmonizacao-facial-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-politica-de-privacidade-js": () => import("./../../../src/pages/politica-de-privacidade.js" /* webpackChunkName: "component---src-pages-politica-de-privacidade-js" */),
  "component---src-pages-tratamentos-dentarios-js": () => import("./../../../src/pages/tratamentos-dentarios.js" /* webpackChunkName: "component---src-pages-tratamentos-dentarios-js" */),
  "component---src-templates-post-template-js": () => import("./../../../src/templates/postTemplate.js" /* webpackChunkName: "component---src-templates-post-template-js" */),
  "component---src-templates-servicos-template-js": () => import("./../../../src/templates/servicosTemplate.js" /* webpackChunkName: "component---src-templates-servicos-template-js" */)
}

